<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				hero:{},
				input:{}
			}
		},
		computed:{
			mrValidation(){
				return this.$root.config.mrValidation.wineries
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			this.apiGetStaticHero()
			this.$set(this.input, 'country','')
		},
		methods: {
			async getStaticHero(id){
					return (await Gen.apirest('/static-hero', {id:id}, ()=>{})).data
			},
			async apiGetStaticHero(){
					this.hero = await this.getStaticHero(6)
					setTimeout(() => { SEMICOLON.documentOnLoad.init() }, 50);
			},
			onSubmit(e){
				this.loadingOverlay=true
				Gen.apirest("/submit-wineries",this.input,(err,resp)=>{
					console.log(err)
					this.loadingOverlay=false
					if(err) return swal(err.resp.message,"","warning")
					this.input={}
					$("html,body").animate({ scrollTop: 0 }, 300);
					swal(resp.message,'','success')
				},"POST")
			}
		},
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element boxed-slider notoppadding">
		    <div class="clearfix">
		        <div class="fslider" data-touch="false" data-easing="easeInQuad">
		            <div class="flexslider">
		                <div class="slider-wrap">
							<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.img_desktop)">
								<img :src="uploader(hero.img_desktop)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero.title }}</h2>
									<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.sub_title">{{hero.sub_title}}</p>
								</div>
							</div>
							<div v-else class="slide" :data-thumb="uploader(hero.img_mobile)">
								<img :src="uploader(hero.img_mobile)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero.title }}</h2>
								</div>
							</div>
						</div>
		            </div>
		        </div>
		    </div>
		</section>
		<section id="content" style="background-color: #000; overflow: visible">
			<div class="content-wrap pt-0 pb-0">
				<div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
					<div class="container clearfix">
						<div class="row  justify-content-center ">
							<div class="col-lg-6 col-md-7">
								<div class="wrap_join">
									<h2 class="text-left">{{web.lbl_become_wineries}}</h2>
									<p class="text-left">{{web.lbl_detail_become_wineries}} </p>
									<VForm @resp="onSubmit">
										<div class="row">
											<div class="col-md-12">
												<label>{{web.lbl_name_winery}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<input type="text" v-model="input.fullname" name="fullname" onkeydown="return fullNameKey(event)" v-bind="validation('fullname')" class="frm_custom_sign" :placeholder="web.lbl_name_winery">
													<LabelError name="fullname"></LabelError>
												</div>
											</div>
											<div class="col-md-12">
												<label>{{web.lbl_no_handphone}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<input type="tel" v-model="input.phone" name="phone" onkeydown="return mobileKey(event)" v-bind="validation('phone')" class="frm_custom_sign" placeholder="No Handphone">
													<LabelError name="phone"></LabelError>
												</div>
											</div>
											<div class="col-md-12">
												<label>{{web.lbl_website}}</label>
												<div class="form-group">
													<input type="text" v-model="input.wibsite" name="" class="frm_custom_sign" placeholder="https://mywebsite.com">
													<LabelError name="wibsite"></LabelError>
												</div>
											</div>
											<div class="col-md-12">
												<label>{{web.lbl_country}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group select_custom_dark">
													<select2 :url="'/select-country-form'" :object="['id','value']" v-model="input.country" name="country" class="valid">
														<option value="">{{web.lbl_select_country}}</option>
													</select2>
												</div>
													<LabelError name="country"></LabelError>
											</div>
											
											<div class="col-md-12">
												<label>{{web.lbl_message}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<textarea v-model="input.message" name="message" v-bind="validation('message')" class="frm_custom_sign h_auto" value="" rows="4" :placeholder="web.lbl_message"></textarea>
													<LabelError name="message"></LabelError>
												</div>
											</div>
											<div class="col-lg-6 col-md-8">
												<label>{{web.lbl_company_profile}} <small class="mandatory_txt">*</small>
												</label>
												<div class="row">
													<div class="col-2" v-if="input.doc"><ImagePreview :src="uploader(input.doc)"></ImagePreview></div>
													<div class="col-10"><Uploader name="bu_pic" noPreview noHelp type="doc_wineries" isFrontend v-model="input.doc"></Uploader>
													<small>File Type : pdf (Max 2 mb)</small></div>
												</div>
											</div>
											<div class="col-md-12 text-center">
												<p><button type="submit" class="btn_line w_100 btn-loading">{{web.btn_submit}} <i
												class="icon-line-arrow-right"></i></button></p>
											</div>
										</div>
									</VForm>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>
		</section>
	</div>
</template>